<template>
<div>
    <div class="site-section bg-light">
        <h1 class="mb-2" style="text-align: center; text-transform: uppercase">{{$t("pages.investors.title")}}</h1>
        <div class="container">
            <div class="row justify-content-center">
            <div class="col-md-12" data-aos="fade-up" data-aos-delay="100">
                <br />
                <p style="text-align:justify; font-size:18px; ">
                {{$t("pages.investors.description")}} 
                </p>
                <p style="text-align:justify; font-size:18px; ">
                {{$t("pages.investors.description_two")}} 
                </p>
            </div>
            </div>
            </div>
        </div>
        </div>
</template>

<script>
export default {
    created() {
        window.scrollTo(0, 0);
    },
};
</script>

<style scoped>
.weight{
    font-weight: 600 !important;
}
</style>