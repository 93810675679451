<template>

<div class="site-blocks-cover inner-page-cover overlay aos-init aos-animate parallax-banner" :style="`background-image: url(${image});`" data-aos="fade" data-stellar-background-ratio="0.5">
      <div class="container">
        <div class="row align-items-center justify-content-center text-center">
          <div class="col-md-10">
            <h1 class="mb-2">{{$t(`${pageTitle}`)}}</h1>
          </div>
        </div>
      </div>
    </div>

   
</template>
<script>
export default {
    props:{
        image:{
            type:String,
            required:true
        },
        pageTitle:{
            type:String,
            required:true
        }
    }
}
</script>
<style scoped>
.parallax-banner{
    height: calc(35vh);
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}
@media only screen and (max-width:991px) {
  h1{
    font-size: 2rem;
  }
}
@media only screen and (max-width:767px){
  h1{
    font-size: 1.4rem;
  }
}
</style>
