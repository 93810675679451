<template>
  <form  v-on:submit.prevent="searchProps" class="form-search col-md-12">
    <div class="row justify-content-center align-items-end">
      <!-- KEYWORD YA -->
      <div v-if="!search.searchDevelopments" class="col-md-3 mb-3">
        <label for="list-types">KEYWORD </label>
        <div class="select-wrap">
          <input
            type="text"
            v-model="search.keyword"
            :placeholder="$t('layout.header.search_form.keyword_input')"
            class="form-control d-block rounded-0"
          >
        </div>
      </div>
      <!-- PRICE MIN  YA -->
      <div class="col-md-3 mb-3">
        <label for="offer-types">{{$t("layout.header.search_form.min_price_input")}}</label>
        <div class="select-wrap">
          <money
            type="text"
            class="form-control d-block rounded-0"
            step="any"
            name="minprice"
            v-model="search.minPrice"
            v-bind="moneyMin"
          ></money>
        </div>
      </div>
      <!-- PRICE MAX YA -->
      <div class="col-md-3 mb-3">
        <label for="select-city">{{$t("layout.header.search_form.max_price_input")}}</label>
        <div class="select-wrap">
          <money
            type="text"
            class="form-control d-block rounded-0"
            step="any"
            name="maxprice"
            v-model="search.maxPrice"
            v-bind="moneyMax"
          ></money>
        </div>
      </div>
      <!-- PROPERTY TYPE -->
      <div class="col-md-3 mb-3" v-if="!search.searchDevelopments">
        <label for="select-city">{{$t(`layout.header.search_form.${(!search.searchDevelopments)?'property_type_input':'development_type_input'}`)}}</label>
        <div class="select-wrap">
          <span class="icon icon-arrow_drop_down"></span>
          <select
            name="select-type"
            id="select-type"
            v-model="search.type"
            class="form-control d-block rounded-0"
          >
            <option selected value="">
              {{ $t("layout.header.search_form.option_select") }}
            </option>
            <option v-for="(type,i) in (!search.searchDevelopments)?input.types:input.typesDevs" :key="i" :index="i" :value="type.typepropertyid">{{type.typepropertyspa}}</option>
          </select>
        </div>
      </div>
      <!-- OPERATION -->
      <div v-if="!search.searchDevelopments" class="col-md-3 mb-3">
        <label for="select-city">{{$t("layout.header.search_form.operation_input")}}</label>
        <div class="select-wrap">
          <span class="icon icon-arrow_drop_down"></span>
          <select
            name="select-city"
            id="select-city"
            v-model="search.operation"
            class="form-control d-block rounded-0"
          >
              <option selected value="">
                  {{ $t("layout.header.search_form.option_select") }}
              </option>
              <option value="1">
                {{ $t("layout.header.search_form.option_sold") }}
              </option>
              <option value="2">
                {{ $t("layout.header.search_form.option_rent") }}
              </option>
              <option value="3">
                {{ $t("layout.header.search_form.option_vacation") }}
              </option>
          </select>
        </div>
      </div>
      <!-- location -->
      <div class="col-md-3 mb-3">
        <label for="select-city">{{$t("layout.header.search_form.location_input")}}</label>
        <div class="select-wrap">
          <span class="icon icon-arrow_drop_down"></span>
          <select
            name="select-city"
            id="select-city"
            class="form-control d-block rounded-0"
            v-model="search.city"
          >
          <option selected value="">{{ $t("layout.header.search_form.option_select") }}</option>
            <option v-for="(city,i) in (!search.searchDevelopments)?input.cities:input.citiesDevs" :key="i" :index="i" :value="city.city">{{city.city}}</option>
          </select>
        </div>
      </div>
      <!-- Type Search -->
      <div v-if="nameRoute!='Agent'" class="col-md-3 mb-3 text-center">
        
          <label class="label-switch">{{$t("layout.header.search_form.type_search")}}: </label>
          <label class="switch span">
            <input v-model="search.searchDevelopments" @click="changeForm" type="checkbox">
            <span class="slider"></span>
          </label>
        

      </div>
      <!-- BUTTON -->
      <div class="col-md-3 mb-3">
        <button
          type="submit"
          class="btn btn-primary  btn-block rounded-0"
        >
        {{$t('layout.header.search_form.button')}}
        </button>
      </div>
    </div>
  </form>
</template>


<script>
import { mapGetters } from "vuex";
import { Money } from "v-money";
export default {
  components: {
    Money,
  },
  data() {
    return {
      search: {
        operation: "",
        type: "",
        typeDevs: "",
        city: "",
        keyword: "",
        minPrice: "",
        maxPrice: "",
        urbanization: "",
        beds: "",
        baths: "",
        parking: "",
        searchDevelopments: false,
      },
      searching: false,
      moneyMin: {
       thousand: ",",
        prefix: "$",
        precision: 0,
        masked: false,
      },
      moneyMax: {
        thousand: ",",
        prefix: "$",
        precision: 0,
        masked: false,
      },
      advanced: true,
    };
  },
  computed: {
    ...mapGetters({
      input: "_getInputs",
      agent:'_getDataAgent',
    }),
    nameRoute() {
      return this.$route.name;
    },
  },
  methods: {
    searchProps() {
      this.searching = true;
      let searchForm = this.search;
      if(this.nameRoute=='Agent'){
        searchForm.user=this.agent.folio
        this.$store.dispatch("getResults", searchForm);
        return false;
      }
      this.$store.dispatch("getResults", searchForm);

      if(!this.search.searchDevelopments){
        if(this.nameRoute!='Results')
          this.$router.push({name: 'Results'});
      }else{
        if(this.nameRoute!='ResultsDevs')
          this.$router.push({name: 'ResultsDevs'});
      }

      this.searching = false;
    },
    changeForm() {
      for(let i in this.search){
        if(i!='searchDevelopments')
        this.search[i]=""
      }
    },
  },
  created(){
    this.$store.dispatch("getInputs");
    console.log(this.$route.name)
    if(this.$route.name=='Developments' ||this.$route.name=='ResultsDevs'){
      this.search.searchDevelopments=true
    }
  }
};
</script>


<style>
@media only screen and (max-width: 1200px) {
  .btn_search #search {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 300px) {
  .custom-dropdown {
    width: 100%;
  }
  .custom-dropdown select {
    width: 100%;
  }
  .big {
    font-size: 13px;
  }
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #53B84F;
}

input:focus + .slider {
  box-shadow: 0 0 1px #53B84F;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.label-switch{
      margin-right: 30px;
}


.btn-primary {
    color: #fff !important;
    background-color: #53B84F !important;
}

.btn-primary:hover {
    color: #fff !important;
    background-color: rgba(83,184,79,.7) !important;
}
</style>
