
<template lang="html">
	<!--<ip-search v-if="searchCompleted" :properties="propertiesFound"></ip-search>-->
	<div>
		<!-- Slider
		================================================== -->
		<HomeCarousel :items="items"/>
		
    <!-- Search    
		================================================== -->  
    <SearchForm />

		<!-- Properties sold
		================================================== -->           
    <div>
      <div class="site-section site-section-sm pb-0 bg-light">
        <div class="container">
          <div class="row">
            <h1 class="d-inline-block  px-3 mb-3 property-offer-type rounded"> {{ $t("pages.home.title_properties") }} </h1>      
          </div>
        </div>
      </div>
    
      <div class="site-section site-section-sm bg-light">      
        <div class="container">
          <div class="row mb-5 justify-content-center">
            <SectionLoader v-if="!properties"/>
            <PropertyCard  
              v-else
              v-for="(item, i) in properties"
              :key="item.folio"
              :name="item.folio"
              :index="i"
              :item="item" 
            />
          </div>	
        </div>	
      </div>      
    </div> 
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SearchForm from "@/components/search/SearchForm.vue";
import PropertyCard from "@/components/properties/PropertyCard.vue";
import HomeCarousel from "@/components/carousel/HomeCarousel.vue";
import SectionLoader from "@/components/layout/SectionLoader.vue";
export default {
  components: {
    HomeCarousel,
    SearchForm,
    PropertyCard,
    SectionLoader
  },
  computed: {
    ...mapGetters({
      items:'_getHomeSlide',
      properties:'_getHomeProperties',
      error:'_getError',
    }),
  },
  created(){
    this.$store.dispatch('getHomeSlide');
    this.$store.dispatch('getHomeProperties');
  }
};
</script>

<style>
.listing-item {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: block;
  width: 100%;
  height: auto;
  border-radius: 3px;
  margin-bottom: 10px;
}
#propImg {
  width: 100%;
  height: 100%;
}
</style>
